<template>
  <div>
    <districts-add></districts-add>
  </div>
</template>

<script>
import DistrictsAdd from "@/components/ecologist/districts/districtsAdd";
export default {
  name: "districtsAddView",
  components: { DistrictsAdd },
};
</script>

<style scoped></style>
